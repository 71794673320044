import { createStyles } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';

import { Text } from '~/domains/design-system/Text';
import type { Lineup } from '../../../types/propPickProps.type';

const useStyles = createStyles(
  (theme, { variant }: { variant: 'badge' | 'avatarPill' | 'pill' }) => ({
    badge: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      gap: 0,
    },
    avatarPill: {
      position: 'absolute',
      bottom: -10,
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      gap: 0,
    },
    pill: {
      display: 'flex',
      marginTop: theme.other.spacing._4,
    },
    inner: {
      height: 22,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    starting: {
      backgroundColor: variant === 'avatarPill' ? theme.white : theme.colors.green[6],
    },
    notStarting: {
      backgroundColor: theme.white,
    },
    badgeInner: {
      gap: theme.other.spacing._2,
      paddingLeft: 10,
    },
    pillInner: {
      gap: theme.other.spacing._2,
      padding: '0 8px',
      borderRadius: 100,
    },
    avatarPillInner: {
      gap: theme.other.spacing._2,
      padding: '0 8px',
      borderRadius: 100,
      border: `1px solid ${theme.colors.gray2[2]}`,
    },
  })
);

function BadgeShapeRight({ color }: { color: string }) {
  return (
    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22L13 22L7.90948 5.62512C6.86945 2.27959 3.77358 3.29896e-07 0.270116 2.36141e-08L1.9233e-06 0L0 22Z"
        fill={color}
      />
    </svg>
  );
}

type PlayerCellBadgeProps = {
  playerLineup: Lineup;
  hasHomeLineup: boolean;
  hasAwayLineup: boolean;
  variant?: 'badge' | 'pill' | 'avatarPill';
};

function PlayerCellBadge({
  playerLineup,
  hasHomeLineup,
  hasAwayLineup,
  variant = 'badge',
}: PlayerCellBadgeProps) {
  const { cx, classes, theme } = useStyles({ variant });

  if (hasHomeLineup && hasAwayLineup && !playerLineup) {
    return (
      <div className={cx(classes[variant])}>
        <div className={cx(classes.inner, classes[`${variant}Inner`], classes.notStarting)}>
          <IconX stroke={3} size={10} color={theme.colors.red[6]} />
        </div>
        {variant === 'badge' ? <BadgeShapeRight color={theme.white} /> : null}
      </div>
    );
  }

  if (playerLineup) {
    return (
      <div className={cx(classes[variant])}>
        <div className={cx(classes.inner, classes[`${variant}Inner`], classes.starting)}>
          {playerLineup?.order ? (
            <Text
              variant="headline-small"
              color={variant === 'avatarPill' ? theme.colors.green[6] : theme.white}
            >
              {playerLineup.order}
            </Text>
          ) : null}
          <IconCheck
            stroke={5}
            size={10}
            color={variant === 'avatarPill' ? theme.colors.green[6] : theme.white}
          />
        </div>
        {variant === 'badge' ? <BadgeShapeRight color={theme.colors.green[6]} /> : null}
      </div>
    );
  }

  return null;
}

export { PlayerCellBadge };
