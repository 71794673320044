import { PlayerCellBadge } from '~/domains/contest/domains/quickPick/components/Board/PlayerCell/PlayerCellBadge';
import type { Lineup } from '~/domains/contest/domains/quickPick/types/propPickProps.type';

type GamecenterPlayerDetailHeaderLineupBadgeProps = {
  hasHomeLineup: boolean;
  hasAwayLineup: boolean;
  playerLineup: Lineup;
};

function GamecenterPlayerDetailHeaderLineupBadge({
  hasHomeLineup,
  hasAwayLineup,
  playerLineup,
}: GamecenterPlayerDetailHeaderLineupBadgeProps) {
  return (
    <PlayerCellBadge
      hasHomeLineup={hasHomeLineup}
      hasAwayLineup={hasAwayLineup}
      playerLineup={playerLineup}
      variant="pill"
    />
  );
}

export { GamecenterPlayerDetailHeaderLineupBadge };
